const formatObjectToString = (obj) => {
    console.log(obj)
    if (!obj) return 'No data available.';
  
    // Format number as currency
    const formatMoney = (number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(number);
    };
  
    // Get the numeric value and its key
    const getNumericValue = (obj) => {
      const numericKeys = [
        'maxAmount', 
        'totalCost',
        'totalExpense', 
        'totalAmount', 
        'averageAmount',
        'count', 
        'maxCost',
        'averageExpense',
        'averageCost',
        'total_analog_lines',
        'totalExpenses',
        'total',
        'totalSum',
        'totalTaxes',
        'totalOneTimeCharge',
        'amount', 
        'taxes', 
        'oneTimeCharge'
      ];
      for (const key of numericKeys) {
        if (obj[key] !== undefined) {
          return { key, value: obj[key] };
        }
      }
      return null;
    };
  
    // Get the classification/category/concept value and its key
    const getClassificationValue = (obj) => {
      const classKeys = [
        'generalClassification', 
        'maxCategory', 
        'maxConcept',
        'mostExpensiveConcept',
        'description',  
        'location',
        'active_services',
        'services',
        'city',
        'classification'
      ];
      for (const key of classKeys) {
        if (obj[key] !== undefined) {
          return { key, value: obj[key] };
        }
      }
      return null;
    };
  
    let result = '';
  
    // Add ID if it exists and isn't null
    if (obj._id && obj._id !== null) {
      result += obj._id;
    }
  
    // Add tn if it exists
    if (obj.tn) {
      result += `TN: ${obj.tn}\n`;
    }
  
    // Add classification if it exists
    // const classification = getClassificationValue(obj);
    // if (classification) {
    //   result += result ? ` ${classification.value}` : classification.value;
    // }

    const classification = getClassificationValue(obj);
    if (classification) {
      result += `Classification: ${classification.value}\n`;
    }
  
    // Add numeric value at the end
    const numericValue = getNumericValue(obj);
    console.log('valor', numericValue)
    if (numericValue) {
      const formattedValue = numericValue.key === 'count' 
        ? numericValue.value 
        : formatMoney(numericValue.value);
      result += result ? ` ${formattedValue}` : formattedValue;
    }

    // const numericValue = getNumericValue(obj);
    // if (numericValue) {
    //   const formattedValue = numericValue.key === 'count' 
    //     ? numericValue.value 
    //     : formatMoney(numericValue.value);
    //   result += `${numericValue.key.replace(/([A-Z])/g, ' $1')}: ${formattedValue}\n`;
    // }

    // Add all other fields not captured above
    for (const [key, value] of Object.entries(obj)) {
      if (key !== '_id' && key !== 'tn' && key !== classification?.key && key !== numericValue?.key) {
        const formattedKey = key.replace(/([A-Z])/g, ' $1'); // Convert camelCase to words
        const formattedValue = typeof value === 'number' ? formatMoney(value) : value;
        result += ` ${formattedKey}: ${formattedValue}\n`;
      }
    }

    if (!result) result = 'Lo siento no sé como responder tu pregunta, dame más detalles por favor.'

    return result;
  };

/**
 * Generic deduplication function using Set
 * @param {Array} array - Array of objects to deduplicate
 * @returns {Array} - Deduplicated array
 */
function dedupeByObject(array) {
  if (!Array.isArray(array)) {
      throw new Error('Input must be an array');
  }
  
  return Array.from(new Set(array.map(item => JSON.stringify(item))))
      .map(item => JSON.parse(item));
}

/**
* Generic deduplication function using a specific key
* @param {Array} array - Array of objects to deduplicate
* @param {string} key - Key to check for duplicates
* @returns {Array} - Deduplicated array
*/
function dedupeByKey(array, key) {
   if (!Array.isArray(array)) {
       throw new Error('Input must be an array');
   }
   
   if (!key || typeof key !== 'string') {
       throw new Error('Key must be a non-empty string');
   }
   
   return array.reduce((acc, current) => {
       const exists = acc.find(item => item[key] === current[key]);
       if (!exists) {
           acc.push(current);
       }
       return acc;
   }, []);
}

export {
    formatObjectToString,
    dedupeByObject,
    dedupeByKey
}